<template>
  <b-card
    title="Supplier Return Summary"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="items"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      sort-by="pending_return_count"
      sort-desc
      class="compact-table"
      sticky-header="calc(100vh - 190px)"
    />
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BTable, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard, BSpinner, BTable, BAlert,
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      items: [],
      tableFields: [
        { key: 'name', label: 'Supplier', sortable: true },
        { key: 'pending_return_count', label: 'Pending Returns Count', sortable: true },
      ],
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.loading = true
      axios.get('/returns/supplier_returns_summary')
        .then(res => {
          this.items = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>
